import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Router, navigate } from "@reach/router";
import Layout from "../../layout/default/Layout";

import { SearchBuyPropertyPage } from "@coninsa-site/property";

function Landing({ data, location }) {

  const cityName = location.pathname.match(
      /^\/venta-de-usados\/inmuebles\/ciudad\/*/
    )
      ? location.pathname
          .replace("/venta-de-usados/inmuebles/ciudad/", "")
          .split("/")[0]
      : null;
  
    const title = cityName
      ? `Venta de Inmuebles Usados en en ${capitalizeFirstLetter(
          cityName
        )} | Coninsa`
      : `Venta de Inmuebles Usados en Colombia | Coninsa`;
  
    const description = cityName
      ? `Descubre las mejores oportunidades de inmuebles usados en venta en ${capitalizeFirstLetter(
          cityName
        )} con Coninsa. Encuentra apartamentos, casas y propiedades comerciales de segunda mano en excelentes condiciones y ubicaciones privilegiadas. Invierte con confianza y aprovecha nuestros precios competitivos y nuestra experiencia en el mercado inmobiliario.`
      : `Descubre las mejores oportunidades de inmuebles usados en venta en Colombia con Coninsa. Encuentra apartamentos, casas y propiedades comerciales de segunda mano en excelentes condiciones y ubicaciones privilegiadas. Invierte con confianza y aprovecha nuestros precios competitivos y nuestra experiencia en el mercado inmobiliario.`;

  return (
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="canonical"
          href={`https://www.coninsa.co${location.pathname}`}
        />
      </Helmet>

      <Router basepath="/venta-de-usados/inmuebles">
        <SearchBuyPropertyPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export const query = graphql`
  query PropertyPageQuery {
    drupal {
      ads: getAdsByUrl(path: "/venta-de-usados/inmuebles") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }
    }
  }
`;

export default Landing;
