import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Slider } from 'ui'
import TimelineItem from './TimelineItem'

function Timeline({ desc, items, name, code  }) {
  const titleStyles = {
    color: 'neutral',
    fontFamily: 'heading',
    fontSize: 3,
    fontWeight: 'heading',
    mb: 'small',
  }

  const descStyles = {
    fontSize: 1,
    mb: 'medium',
  }

  const settings = {
    arrows: true,
    arrowsPosition: 'bottom',
    autoplay: false,
    variant: 'colored',
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
    ],
  }
  
  return (
    <Box sx={{ mb: ['60px', 0] }}>
      <Box sx={titleStyles}>Avances de proyecto {name}</Box>
      <Box sx={descStyles}>{desc}</Box>
      <Box sx={{ mx: [0, 'xlarge'] }}>
        <Box sx={{ display: 'flex', gap: [1, 2] }}>
          <Slider autoHeight={false} {...settings} sx={{ width: '100%' }}>
            {items.map(item => (
              <TimelineItem {...item} code={code}/>
            ))}
          </Slider>
        </Box>
      
        <Box
          sx={{
            border: '4px solid',
            borderColor: '#9fc3f4',
            borderRadius: '3px',
            mt: ['-21px', '-49px']
          }}
        />
      </Box>
    </Box>
  )
}

Timeline.defaultProps = {
  desc: '',
  items: [],
}

Timeline.propTypes = {
  desc: PropTypes.string,
  code: PropTypes.string,
  items: PropTypes.shape({
    map: PropTypes.func,
    reverse: PropTypes.func,
  }),
  name: PropTypes.string.isRequired,
}

export default Timeline
