import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import SlippedCard from "@coninsa-s2/slipped-card";
import DialogBox from "@coninsa-s2/dialog-box";

import lonjaBrand from "../assets/images/lonja.png";
import lonjaPropertyBrand from "../assets/images/lonja-property.png";
import camacolBrand from "../assets/images/camacol.png";
import colombianChamberBrand from "../assets/images/colombian-chamber.png";

const data = [
  {
    id: "member-1",
    icon: lonjaBrand,
    title: "Lonja de Propiedad Raíz de Antioquia",
    desc: "Contribuye al perfeccionamiento el conjunto de actividades relacionadas con el mercado de la Propiedad Raíz, promover directa e indirectamente el entendimiento eficaz entre los diversos grupos sociales y económicos que participan en el mercado inmobiliario, en demanda o en oferta de bienes y servicios en sus diferentes modalidades.",
  },
  {
    id: "member-2",
    icon: lonjaPropertyBrand,
    title: "Lonja de Propiedad Raíz de Barranquilla",
    desc: "Agrupa a personas y empresas vinculadas al sector inmobiliario en las áreas de promoción, gerencia y venta de proyectos, corretaje y propiedad raíz, administración y arrendamiento de bienes inmuebles, avalúos, administración de propiedad horizontal y consultoría inmobiliaria.",
  },
  {
    id: "member-3",
    icon: camacolBrand,
    title: "Camacol",
    desc: "Es un gremio sectorial que contribuye significativamente a la consolidación de la actividad de la construcción, en un entorno globalizado.",
  },
  {
    id: "member-4",
    icon: colombianChamberBrand,
    title: "Cámara Colombiana de la Infraestructura",
    desc: "Promueve el desarrollo socioeconómico a través de una infraestructura moderna y eficiente, defiende la institucionalidad, los principios éticos y la transparencia, busca el equilibrio en las relaciones contractuales y propende por el fortalecimiento de las empresas que intervienen en la cadena de valor y su recurso humano.",
  },
];

export default function Members() {
  const sliderOptions = {
    arrows: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="ligther-gray">
      <Container>
        <div className="absolute mx-auto">
          <DialogBox tint="lime" className="relative -top-16 lg:-top-28">
            <Heading size="4xl" className="!font-extrabold">
              Somos miembros de
            </Heading>
          </DialogBox>
        </div>

        <div className="pt-5 lg:hidden">
          <Splide options={sliderOptions} className="s2-slider s2-slider--lg">
            {data.map((item) => (
              <SplideSlide>
                <div className="h-full px-2 pb-4">
                  <SlippedCard
                    key={item.id}
                    icon={item.icon}
                  >
                    <Heading size="lg" className="leading-6">
                      {item.title}
                    </Heading>
                    <div className="mt-2 text-sm text-s2-gray-600 lg:text-base">
                      {item.desc}
                    </div>
                  </SlippedCard>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>

        <div className="hidden grid-cols-2 gap-4 pt-6 lg:grid">
          {data.map((item) => (
            <SlippedCard key={item.id} icon={item.icon}>
              <Heading size="lg">{item.title}</Heading>
              <div className="mt-2 text-sm text-s2-gray-600 lg:text-base">
                {item.desc}
              </div>
            </SlippedCard>
          ))}
        </div>
      </Container>
    </Section>
  );
}
