import React from "react";
import clsx from "clsx";

import "./styles.css";

interface SlippedCardProps {
  icon?: string;
  children: React.ReactNode;

  tint?: "teal" | "blue" | "lime" | "green";
  className?: string;
}

const SlippedCard: React.FC<SlippedCardProps> = ({
  icon = undefined,
  children,

  tint = "lime",
  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-slipped-card--${tint}`]: tint,
  };

  return (
    <div
      className={clsx("s2-slipped-card", dynamicClasses, className)}
      {...props}
    >
      <div className="s2-slipped-card__icon">
        <img src={icon} alt="#" />
      </div>
      <div className="s2-slipped-card__content">{children}</div>
    </div>
  );
};

export default SlippedCard;
