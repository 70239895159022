import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";

import iso9001Icon from "../assets/images/iso-9001.png";
import iso45001Icon from "../assets/images/iso-45001.png";
import iso14001Icon from "../assets/images/iso-14001.png";

const data = [
  {
    id: "certification-1",
    icon: iso9001Icon,
    title: "Sistema de Gestión de la Calidad",
    desc: `ISO 9001:2015 Número de certificado: <strong>SC088-1</strong>`,
  },
  {
    id: "certification-2",
    icon: iso45001Icon,
    title: "Sistema de Gestión de Seguridad y Salud en el Trabajo",
    desc: `ISO 45001:2018 Número de certificado: <strong>CER107622</strong>`,
  },
  {
    id: "certification-3",
    icon: iso14001Icon,
    title: "Sistema de Gestión Ambiental",
    desc: `ISO 14001:2015 Número de certificado <strong>CER92575</strong>`,
  },
];

export default function Certifications() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "7rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section>
      <Container>
        <div className="block items-center justify-between gap-4 text-center lg:flex lg:text-left">
          <Heading size="4xl" condensed className="!font-medium">
            <span className="block font-extralight text-s2-teal">
              Contamos con
            </span>{" "}
            Certificaciones de Nuestra gestión
          </Heading>

          <Splide
            options={sliderOptions}
            className="s2-slider s2-slider--lg mt-5"
          >
            {data.map((item) => (
              <SplideSlide>
                <div
                  key={item.id}
                  className="h-[370px] rounded-md border border-s2-gray-300 bg-white p-6 lg:h-[400px] lg:w-[280px]"
                >
                  <img
                    src={item.icon}
                    className="mx-auto mb-3 h-40 w-28 lg:h-auto lg:w-auto"
                  />
                  <div className="">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                      className="text-center text-sm font-medium text-s2-gray-600"
                    />
                    <div className="mx-auto my-3 w-20 border-b-2 border-s2-lime"></div>
                    <Heading
                      size="lg"
                      className="text-center !font-bold leading-6"
                    >
                      {item.title}
                    </Heading>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </Container>
    </Section>
  );
}
