import React from "react";
import clsx from "clsx";
import "./styles.css";

interface TableListProps {
  children: React.ReactNode;
  className?: string;
}

const TableList: React.FC<TableListProps> = ({
  children,

  className,
  ...props
}) => {
  return (
    <ul className={clsx("s2-table-list", className)} {...props}>
      {children}
    </ul>
  );
};

export default TableList;
