import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";

import hearthIcon from "../assets/icons/heart.svg";
import peopleIcon from "../assets/icons/people-dark.svg";
import medalIcon from "../assets/icons/medal.svg";
import thumbIcon from "../assets/icons/thumb-up-dark.svg";
import starIcon from "../assets/icons/star.svg";

const data = [
  {
    id: "value-01",
    icon: hearthIcon,
    title: "Defendemos el respeto",
    desc: `<strong>Valoramos a las personas, aceptando que hay diferencias</strong>. Entendemos que debemos aportar a la sociedad que nos acoge y tratamos a los demás con el respeto que se merecen.`,
  },
  {
    id: "value-02",
    icon: peopleIcon,
    title: "Nos entusiasma trabajar en equipo",
    desc: `<strong>Entendemos que los buenos resultados se consiguen haciendo equipo.</strong> Valoramos los aportes en la búsqueda de los objetivos. Nos estimula llegar a acuerdos y cumplirlos.`,
  },
  {
    id: "value-03",
    icon: medalIcon,
    title: "Brindamos una experiencia diferenciadora",
    desc: `<strong>Sabemos que cada Cliente es importante</strong>, entendemos sus necesidades para entregarles productos con valor. Nos apasiona participar en el logro de sus sueños.`,
  },
  {
    id: "value-04",
    icon: thumbIcon,
    title: "Somos confiables",
    desc: `<strong>Nos satisface generar confianza.</strong> Por eso somos coherentes con nuestros principios, cumplimos los compromisos y actuamos transparentemente. Defendemos la honestidad.`,
  },
  {
    id: "value-05",
    icon: starIcon,
    title: "Buscamos la excelencia",
    desc: `<strong>Nos exigimos para mejorar constantemente, optimizar los procesos y generar innovación.</strong> Nos motiva elevar la productividad de los recursos para proporcionar productos y servicios de excelente calidad.`,
  },
];

export default function OurValues() {
  const sliderOptions = {
    arrows: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "3.5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section>
      <Container>
        <Heading size="4xl" condensed className="mb-2 lg:mb-5">
          Nuestros Valores
          <span className="block text-3xl lg:text-4xl text-s2-lime font-normal">
            como Compañía
          </span>
        </Heading>

        <div className="lg:hidden">
          <Splide
            options={sliderOptions}
            className="s2-slider s2-slider--divide-x s2-slider--lg"
          >
            {data.map((item) => (
              <SplideSlide className="px-4" key={item.id}>
                <img src={item.icon} alt="Value icon" className="w-16 h-16" />
                <div>
                  <Heading size="lg" className="leading-5 mb-2">
                    {item.title}
                  </Heading>
                  <div
                    className="text-sm"
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  />
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>

        <div className="hidden lg:block">
          <div className="grid grid-cols-1 divide-y">
            {data.map((item) => (
              <div key={item.id} className="flex py-4 gap-4">
                <img src={item.icon} alt="Value icon" />
                <div>
                  <Heading size="lg" className="mb-2">
                    {item.title}
                  </Heading>
                  <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
}
