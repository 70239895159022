import React, { useEffect } from "react";
import { navigate } from "gatsby";

const NotFoundPage = () => {
    useEffect(() => {
        // Redirigir inmediatamente al home
        navigate("/");
    }, []);

    return null; // No renderiza nada mientras redirige
};

export default NotFoundPage;
