import React from "react";
import clsx from "clsx";

import "./styles.css";

interface FigureCardProps {
  children: React.ReactNode;

  tint?: "teal" | "blue" | "lime" | "green";
  className?: string;
}

const FigureCard: React.FC<FigureCardProps> = ({
  children,

  tint = "lime",
  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-figure-card--${tint}`]: tint,
  };

  return (
    <div className={clsx("s2-figure-card", dynamicClasses, className)} {...props}>
      {children}
    </div>
  )
}

export default FigureCard;
